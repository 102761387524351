import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"

  export default function Header(props) {
    const data = useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
        }
      `
    )
    return <div style={{background: "black", color: "white"}}>
            <div>
              <h5 style={{margin: "0"}}>
                この部分は作業用のショートカットリンク集です。公開時には削除します。</h5>
            </div>
            <div style={{textAlign: "center", display: "flex", justifyContent: "space-around", flexWrap: "wrap", color: "white" }}>
              <div>
              <Link style={{color: "white" }} to="/top">HOME</Link>
              </div>
              <div>
              <Link style={{color: "white" }} to="/themeset">テーマの選択画面</Link>
              </div>
              <div>
              <Link style={{color: "white" }} to="/sdgs">SDGsから探す</Link>
              </div>
              <div>
              <Link style={{color: "white" }} to="/cityset">都市の設定画面</Link>
              </div>
              <div>
              <Link style={{color: "white" }} to="/faq">質問と回答</Link>
              </div>
              <div>
              <Link style={{color: "white" }} to="/">新着順</Link>
              </div>
              <div>
              <Link style={{color: "white" }} to="/mdlist">登録情報一覧</Link>
              </div>
              <div>
              <Link style={{color: "white" }} to="https://univ.kashika.net/20201202-2/">技術解説</Link>
              </div>
            </div>
          </div>
  }
